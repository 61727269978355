<template>
	<ion-page>
		<!-- Title toolbar disamping icon hamburger menu -->
		<ion-header :translucent="true">
			<ion-toolbar color="ari" class="ion-text-center">
				<ion-title>Permintaan Konsultasi</ion-title>
			</ion-toolbar>
			<ion-progress-bar
				v-if="store.state.isLoading"
				type="indeterminate"
			></ion-progress-bar>
		</ion-header>

		<ion-content :fullscreen="true" slot="fixed">
			<ion-list class="conversation" v-if="store.state.ticket.request != null">
				<ion-item
					color="goldpalete"
					v-for="(d, i) in store.state.ticket.request"
					:key="i"
					@click="goToChat(d.TICKET_NO)"
				>
					<!-- <ion-icon :icon="personCircleOutline" class="personIcon"></ion-icon> -->
					<!-- new code -->
					<img
						src="@/assets/relidoc/permintaan.svg"
						alt=""
						class="icon-width-2"
					/>
					<ion-label class="list-chat">
						<span class="blue-ari">{{ formatDate(d.TICKET_START_DATE) }}</span
						><br />
						<span>Nomor Tiket : {{ d.TICKET_NO }}</span
						><br />
						<span
							>Pasien : <b>{{ d.MEMBER_NAME }}</b></span
						><br />
						<!-- <span v-if="d.POLICY_NAME != null"
							>Perusahaan : {{ d.POLICY_NAME }}</span
						><br /> -->
						<span>Indikasi : {{ d.TICKET_INDICATION }}</span
						><br />
						<span>Status : {{ d.TICKET_STATUS_NAME.toLowerCase() }}</span>
					</ion-label>
				</ion-item>
			</ion-list>
			<div v-if="store.state.ticket.request == null">
				<div class="flex">
					<div class="no-content">
						<!-- <ion-icon :icon="alertCircleOutline"></ion-icon><br />
						<span>Tidak ada permintaan konsultasi</span> -->

						<!-- new code -->
						<div class="icon-wrap">
							<img
								src="@/assets/relidoc/informasi.svg"
								alt=""
								class="icon-width"
							/>
							<span>Tidak ada permintaan konsultasi</span>
						</div>
					</div>
				</div>
			</div>
			<!-- <pre>
				{{ store.state.ticket }}
			</pre
			> -->
		</ion-content>
		<bottom-tab-bar></bottom-tab-bar>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	// IonIcon,
	IonLabel,
	IonProgressBar,
	onIonViewWillEnter,
	onIonViewWillLeave,
} from "@ionic/vue";
import { personCircleOutline, alertCircleOutline } from "ionicons/icons";
import { inject } from "vue";
import { useRouter } from "vue-router";
import BottomTabBar from "@/components/BottomTabBar.vue";
import { formatDate } from "@/store/methods/utils/utils";
import db from "@/fb";
export default {
	name: "Conversation",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonList,
		IonItem,
		// IonIcon,
		IonLabel,
		IonProgressBar,
		BottomTabBar,
	},
	setup() {
		const store = inject("store");
		const router = useRouter();
		var unsubscribe = null;
		const entryticket = db.collection("entryticket").doc("status");
		onIonViewWillEnter(() => {
			//store.ticket.listRequestConsultation();
			setRealtime();
		});

		function setRealtime() {
			//console.log("set realtime");
			unsubscribe = entryticket.onSnapshot(async (docSnapshot) => {
				if (docSnapshot.data() != null) {
					//console.log("yes trigered");
					store.ticket.listRequestConsultation();
				}
			});
		}
		onIonViewWillLeave(() => {
			unsubscribe();
		});
		//refresh and get new data every 15 secend
		// setInterval(function() {
		// 	store.ticket.refreshListRequestConsultation();
		// }, 10000);
		function goToChat(ticketId) {
			router.replace(`/ticket-information/${ticketId}`);
		}
		return {
			store,
			personCircleOutline,
			alertCircleOutline,
			formatDate,
			goToChat,
		};
	},
};
</script>

<style scoped>
.icon-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.icon-width {
	width: 44px;
	margin-bottom: 10px;
}
.icon-width-2 {
	width: 44px;
	margin-right: 15px;
	margin-left: 5px;
}
ion-item {
	cursor: pointer;
}
.personIcon {
	color: var(--ion-color-ari);
	margin-right: 5px;
	margin-left: 0px;
	font-size: 50px;
}
.list-chat {
	color: #464947;
	padding: 0;
}
.conversation {
	padding: 0;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 79vh;
	overflow: auto;
}
.no-content {
	justify-content: center;
	text-align: center;
	color: rgb(179, 179, 179);
}
.no-content > ion-icon {
	font-size: 50px;
	color: rgb(179, 179, 179);
}
</style>
